export const tempFullLanguage = 'English';
export const tempShortLanguage = 'en';
export const programDescriptorKey = 'c37cd379-606a-ee11-9937-000d3aae6876';

export const xlDataForReport = [
	{
		titleWidth: 300,
		descriptionWidth: 330,
		resourceTypeWidth: 220,
		reportUploadedTimeWidth: 170,
		viewActionWidth: 120
	}
];
export const xxlDataForReport = [
	{
		titleWidth: 340,
		descriptionWidth: 380,
		resourceTypeWidth: 220,
		reportUploadedTimeWidth: 180,
		viewActionWidth: 200
	}
];
export const lgDataForReport = [
	{
		titleWidth: 300,
		descriptionWidth: 330,
		resourceTypeWidth: 220,
		reportUploadedTimeWidth: 170,
		viewActionWidth: 120
	}
];
export const normalDataForReport = [
	{
		titleWidth: 340,
		descriptionWidth: 350,
		resourceTypeWidth: 150,
		reportUploadedTimeWidth: 185,
		viewActionWidth: 170
	}
];

export const xxlDataForReferral = [
	{
		documentNameWidth: 450,
		documentUploadedTimeStampWidth: 200,
		documentStatus: 200,
		documentRejectionReasonWidth: 240,
		viewActionWidth: 200
	}
];
export const normalDataForReferral = [
	{
		documentNameWidth: 450,
		documentUploadedTimeStampWidth: 150,
		documentStatus: 150,
		documentRejectionReasonWidth: 210,
		viewActionWidth: 150
	}
];

export const pageUrls = [
	{
		'Header.Home': '/home',
		'Header.MyAccount': '/my-account',
		'Header.Notifications': '/notifications',
		'Header.Dashboards': '/dashboards',
		'Header.Referrals': '/referrals',
		'Header.ReportsAndResources': '/reports-and-resources',
		'Header.ClientTeam': '/client-team'
	}
];

export const pageNavigations = [
	'Header.MyAccount',
	'Header.Notifications',
	'Header.Dashboards',
	'Header.Referrals',
	'Header.ReportsAndResources',
	'Header.ClientTeam',
	'Header.Logout'
];

export const groupAsectionOrder = ['KEYSTATISTICS', 'SATISFACTION', 'OUTCOMES', 'INVOICING'];
export const groupBsectionOrder = ['KEYSTATISTICS', 'CANDIDATES', 'LOCATION'];

export const dashboardNotAllowed = ['CL4', 'CL7', 'CL9'];
export const reportsNotAllowed = ['CL4', 'CL7', 'CL9'];
export const referralsNotAllowed = ['CL5', 'CL8'];
export const removeAllowed = ['CL1', 'CL3', 'CL9'];
export const meetingsAllowed = ['CL1', 'CL2', 'CL3', 'CL9'];
export const limitCl3 = ['Client Main User', 'Client Second Tier User', 'Client Regional User'];

export const SnackBarMsgType = {
	Success: 1,
	Error: 2,
	Warning: 3,
	Normal: 4
};

export const restrictions = {
	CL1: [],
	CL2: [],
	CL3: [],
	CL4: ['Header.Dashboards'],
	CL5: ['Header.Referrals'],
	CL6: [],
	CL7: ['Header.Dashboards'],
	CL8: ['Header.Referrals'],
	CL9: ['Header.Dashboards']
};

export const defaultNotificationCount = 5;

export function customDateConverter(date) {
	// const receiveddate = new Date(date);
	// const timestamp = receiveddate.getTime();
	// var d = new Date(timestamp);
	// var datestring = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." +
	// d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
	const filteredCreatedDate = new Date(date);
	// let resultdate = datewithDots.replaceAll('-', '/') + ' ' + filteredCreatedDate.toLocaleTimeString();
	let resultdate =
		filteredCreatedDate.toJSON().slice(0, 10).split('-').reverse().join('/') +
		' ' +
		filteredCreatedDate.toLocaleTimeString();
	resultdate.replace('AM', '').replace('PM', '');
	return resultdate.replace('AM', '').replace('PM', '');
}
export function notificationDateConverter(date) {
	const filteredCreatedDate = new Date(date);
	const day = filteredCreatedDate.getDate();
	const month = filteredCreatedDate.toLocaleString('default', { month: 'short' });
	//const day = filteredCreatedDate.toLocaleString('default', { weekday: 'long' });
	//const year = filteredCreatedDate.toLocaleString('default', { year: '2-digit' });
	const year = filteredCreatedDate.getFullYear();
	let today = new Date();
	let isToday = today.toDateString() == filteredCreatedDate.toDateString();
	let formatDate;
	if (isToday) {
		formatDate = 'Today';
	} else {
		//formatDate = day + ', ' + year + ' ' + month;
		formatDate = day + ' ' + month + ' ' + year;
	}

	return formatDate;
}
export function customDateConverterCard(date) {
	const filteredCreatedDate = new Date(date);
	//const date1 = filteredCreatedDate.getDate();
	//const month = filteredCreatedDate.getMonth() + 1;
	//const year = filteredCreatedDate.getFullYear();
	//let resultdate = `${date1}/${month}/${year}`;
	let finalTime = filteredCreatedDate.getHours();
	let finalMins = filteredCreatedDate.getMinutes();
	if (finalMins < 10) {
		finalMins = '0' + finalMins;
	}
	//const time = new Date(filteredCreatedDate.getTime()).toLocaleTimeString();
	//let finalTime = time.split(':');
	// return finalTime[0] + ':' + finalTime[1];
	return finalTime + ':' + finalMins;
}

const pad = num => ('0' + num).slice(-2); // or use padStart

export function getTimeFromDate(timestamp) {
	const date = new Date(timestamp * 1000);
	let hours = date.getHours(),
		minutes = date.getMinutes(),
		seconds = date.getSeconds();
	return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds);
	//return pad(hours) + ':' + pad(minutes);
}

export const ReferralGridHeaders = ['NAME', 'TYPE', 'ADDED ON', 'STATUS', 'STATUS', 'ACTION'];

export function byteConverter(bytes, decimals, only) {
	const K_UNIT = 1024;
	const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

	if (bytes == 0) return '0 Byte';

	if (only === 'MB') return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + ' MB';

	let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
	let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + ' ' + SIZES[i];

	return resp;
}

export const BRAND_ID = '83B1E45D-425B-475D-AD90-AE9533EAC21C';

export function handleError(error) {
	console.log('error', error); //eslint-disable-line
	if (error?.response?.status == 401) {
		sessionStorage.clear();
	} else if (error?.response?.status == 400 || error?.response?.status == 404 || error?.response?.status == 500) {
		//window.location = '/error';
	} else {
		//window.location = '/error';
	}
}

export function capitalizeFirstLetterOfEachWord(str) {
	const arr = str.split(' ');
	for (var i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
	}
	const str2 = arr.join(' ');
	return str2;
}
